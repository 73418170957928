import React, { useEffect, useState } from 'react'
import { Modal } from 'src/components/Modal'
import Popup from 'src/components/Popup'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import useWidthResize from 'src/hooks/window/useWidth'
import Layout from 'src/layouts/BaseLayout'
import { WIDTH_LG } from 'src/utils/breakpoints'
import pageContext from './pageContext.json'
import { Wrapper } from './style'

import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { orange } from 'src/styles/colors'
import { ButtonLink } from './components/Button/_index'
import { Heading2, Paragraph } from './components/Text/_index'
import CartaoDeCreditoMEI from './sections/cartao-de-credito-mei/_index'
import Faq from './sections/faq/_index'
import Hero from './sections/hero/_index'
import MotivosInterEmpresas from './sections/motivos/_index'
import Novidades from './sections/novidades/_index'
import PorqueAbrirContaMEI from './sections/porque-abrir-conta-mei/_index'

const ContaDigitalMEI = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const windowWidth = useWidthResize(200)

  const isDesktop = windowWidth > WIDTH_LG
  const [ popup, setPopup ] = useSessionStorage('@hide-bancointer-popup-conta-digital-mei', false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopUpChild = (
    <div className='row align-items-center justify-content-center'>
      <div className='col-lg-4'>
        <ImageWebpWithSizes
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/popup-pj/image.webp'
          altDescription='Telas da planilha de controle financeiro MEI'
        />
      </div>
      <div className='col-lg-8 mt-4'>
        <Heading2 className='mb-3'>
          Planilha de controle financeiro Mei gratuita
        </Heading2>
        <Paragraph className='fs-24 lh-29 text-grayscale-400'>
          Garanta mais clareza sobre o fluxo de caixa do seu negócio.
        </Paragraph>
      </div>
      <div className='col-lg-12 mt-lg-5'>
        <ButtonLink
          href='https://conta-digital-pj.inter.co/login?utm_source=lp&utm_medium=botao&utm_campaign=aquisicao_lp_pj_botao&utm_term=conta-digital-pj&utm_content=conta_mei'
          target='_blank'
          rel='noopener'
          onClick={() => {
            setIsPopupOpen(false)
            sendDatalayerEvent({
              section: 'dobra_01',
              element_action: 'click button',
              section_name: 'Planilha de controle financeiro Mei gratuita',
              element_name: 'Baixar agora!',
              redirect_url: 'https://conta-digital-pj.inter.co/login?utm_source=lp&utm_medium=botao&utm_campaign=aquisicao_lp_pj_botao&utm_term=conta-digital-pj&utm_content=conta_mei',
            })
          }}
        >
          Baixar agora!
        </ButtonLink>
      </div>
    </div>
  )

  const PopupExitPage = domReady && !popup && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup
        child={PopUpChild}
        closeModal={() => setIsPopupOpen(false)}
        setPopup={setPopup}
        pageName='conta-digital-mei'
        theme={{
          closeButton: orange.extra,
          backgroundColor: '#fff',
        }}
      />
    </Modal>
  )

  return (
    <Wrapper>
      {PopupExitPage}
      <Layout isPJ pageContext={pageContext}>
        <Hero sendDatalayerEvent={sendDatalayerEvent} />
        <PorqueAbrirContaMEI />
        <CartaoDeCreditoMEI sendDatalayerEvent={sendDatalayerEvent} />
        <MotivosInterEmpresas sendDatalayerEvent={sendDatalayerEvent} />
        <Novidades />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalMEI
