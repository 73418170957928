import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

// Components
import Icon from 'src/components/UI/MarkdownIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

// Style
import { BlogCarousel } from './style'

// Assets
import BlogArticlesMEIAccountJSON from '../../assets/data/BlogArticlesMEIAccount.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type BlogArticlesPj = {
  image: string;
  primaryTag: string;
  secundaryTag: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}

const Novidades = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const arrayNumbers: number[] = [
    312, 312, 291, 355,
  ]

  return (
    <section id='dicas-para-investir-melhor' className='bg-white d-flex pb-md-5 pb-lg-0'>
      <div className='container py-5 pb-lg-0'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-20 fs-md-24 fs-lg-32 fs-xl-40 lh-25 lh-md-30 lh-lg-40 lh-xl-50 text-grayscale--500 text-md-center'>
              <span className='d-lg-block'>Quer ficar por dentro das novidades</span> da Conta Digital MEI?
            </h2>
            <p className='fs-18 lh-22 text-grayscale--400 mt-3 text-md-center'>
              <span className='d-lg-block'><a target='_blank' rel='noreferrer' className='fw-600' href='https://blog.inter.co/'>Acompanhe nosso blog</a> e entenda mais sobre educação financeira, investimentos, e o</span>  que mais sua empresa precisar.
            </p>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 2, partialVisibilityGutter: 30 }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
            >
              { BlogArticlesMEIAccountJSON.map((item: BlogArticlesPj, index: number) => (
                <BlogCarousel key={index} className='col-12 p-card mt-md-3'>
                  <article className='col-12 px-0'>
                    <ImageWebp
                      pathSrc={item.image}
                      altDescription={item.alt}
                      arrayNumbers={arrayNumbers}
                    />
                    <div className='col-12 pt-3 rounded-5 px-4 mt-n5 bg-white'>
                      <p className='fs-12 lh-15 fw-600 text-white bg-orange--extra rounded-1 mb-1 px-2 mr-2 primary-tag'>{item.primaryTag}</p>
                      <p className='fs-12 lh-15 fw-600 text-orange--extra bg-gray-400 rounded-1 mb-1 px-2 secundary-tag'>{item.secundaryTag}</p>
                      <p className='fs-20 lh-25 fw-600 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>{item.title}</p>
                      <span className='fs-12 lh-15 fw-400 d-block text-grayscale--300'>{item.data}</span>
                      <p className='fs-14 lh-19 lh-md-19 mb-2 fw-400 d-none d-md-block mt-3 text-grayscale--500'>{item.description}</p>
                      <a
                        data-blog={item.title}
                        href={item.link}
                        title={`Leia mais em ${item.link}`}
                        className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-md-block mt-xl-3'
                        onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_09',
                          element_action: 'click button',
                          section_name: 'Acompanhe as novidades da Conta Digital PJ',
                          element_name: item.title,
                          redirect_url: item.link,
                        })
                        }}
                      >
                        Leia mais
                        <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                      </a>
                    </div>
                  </article>
                </BlogCarousel>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Novidades
